<template>
<div id="homes"> 

  <div >   
     <b-row class="justify-content-center"> 
       <b-col cols="11" md="11"> 
         <b-row class="justify-content-center">  
          <b-col cols="11" md="3" lg="4" xl="4" style="margin-top: -122px;">
            <br v-if="isMobile"><br v-if="isMobile"><br v-if="isMobile"><br><br>             
            <img src="elementos/jonrronero.png" alt="Image" :style="isMobile ? 'margin-left: -20px; width: 115%;' : 'width: 68%;'"  @click="cambiarNav(0)" > 
            <br v-if="!isMobile">
          </b-col> 
           <b-col cols="11" md="4" v-if="!isMobile">

          </b-col>
           <b-col cols="10" md="6"  lg="4" xl="4" >
              <br v-if="!isMobile"><br  v-if="!isMobile"><br  v-if="!isMobile"> 
                <p :class="isMobile ? 'text-white mt-5 text-center' : 'text-white mt-5 text-right'" >
                  
                  <br v-if="isMobile">
                    <span style="font-size:15px; color:#000; "  class="mt-3"> 
                        {{user.nombre}} 
                      </span>
                   <b>  
                      <template v-if="!isMobile">
                        <span class="text-secondary"  v-if="user.canal == 3"><b style="color:#000;">&nbsp;&nbsp; PROPIO</b></span>
                        <span class="text-secondary"  v-if="user.canal == 2"><b style="color:#000;">&nbsp;&nbsp; TELEMARKETING</b></span>
                        <span class="text-secondary"  v-if="user.canal == 1"><b style="color:#000;">&nbsp;&nbsp; RETAIL</b></span> 
                        <span class="text-secondary"  v-if="user.canal == 4"><b style="color:#000;">&nbsp;&nbsp; ESPECIALISTA</b></span>
                      </template>
                      <template v-else-if="isMobile ">  
                        <span class="text-secondary"  v-if="user.canal == 3"><b style="color:#000; font-size: 15px;">&nbsp;&nbsp; PROPIO</b></span>
                        <span class="text-secondary"  v-if="user.canal == 2"><b style="color:#000; font-size: 15px;">&nbsp;&nbsp; TELEMARKETING</b></span>
                        <span class="text-secondary"  v-if="user.canal == 1"><b style="color:#000; font-size: 15px;">&nbsp;&nbsp; RETAIL</b></span> 
                        <span class="text-secondary"  v-if="user.canal == 4"><b style="color:#000; font-size: 15px;">&nbsp;&nbsp; ESPECIALISTA</b></span>
                      </template>
                  </b>
                <br>
                <img  src="elementos/hom.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image"  :style="!isMobile ? 'width:4.5%; cursor:pointer;  margin-top:-2px;' : 'width:5%; cursor:pointer; margin-top:-2px;'" @click="cambiarNav(0)"> 
                <u :class="isMobile ? 'ml-5 mt-5' : 'ml-5 mt-5'" :style="nav == 7 ? 'color:#fff; cursor: pointer; z-index:10;' : 'color:#fff; cursor: pointer; z-index:10;'" @click="cambiarNav(7)">Contacto</u>
                <u :class="isMobile ? 'ml-5 mt-5' : 'ml-5 mt-5'" style="color:#fff; cursor: pointer; z-index:10;" @click="cerrarSesion()">Cerrar sesión</u>
                <br v-if="!isMobile"> 
                </p>
              <br>
            </b-col> 
            <!-- <b-col cols="11" md="1" v-if="!isMobile " class="mt-4">  
              <br><br>
                <center>
                   <template v-if="!user.foto">
                     <b-img v-if="user.sexo == 'M'" src="elementos/miperfil2.png" alt="Image" :style="isMobile ? 'width: 15%; cursor:pointer;' : 'width:55px;  height:55px;  border-radius:150px; cursor:pointer;  margin-top: -2px;'"  @click="cambiarNav(1)"></b-img>
                     <b-img v-if="user.sexo == 'F'" src="elementos/mujer.png" alt="Image" :style="isMobile ? 'width: 15%; cursor:pointer;' : 'width:55px;  height:55px;  border-radius:150px; cursor:pointer;  margin-top: -2px;'"  @click="cambiarNav(1)"></b-img>
                     <b-img v-if="user.sexo == 'G'" src="elementos/gene.png" alt="Image" :style="isMobile ? 'width: 15%; cursor:pointer;' : 'width:55px;  height:55px;  border-radius:150px; cursor:pointer;  margin-top: -2px;'"  @click="cambiarNav(1)"></b-img>
                     <br>
                   </template>
                   <template v-else>
                     <b-img :src="$api.baseUriFiles+'perfil/' + user.foto"  alt="Image"   @click="cambiarNav(1)" style="width:55px;  height:55px;  border-radius:150px; margin-top: -2px;"></b-img><br>
                   </template> 
                  <u  style="color:#fff; cursor: pointer;  " @click="cambiarNav(1)">PERFIL</u>
                </center>
            </b-col> -->
         </b-row>
        </b-col>    
        <b-col cols="10" md="6" lg="6" xl="6" class="text-center" :style="isMobile ? 'margin-top:-7px;' : 'margin-top:-185px;'" >
           <br v-if="isMobile">
            <b-row class="justify-content-center"> 
               <b-col cols="6" md="4" lg="4" xl="4"> 
                  <img :src="nav == 1 ? 'elementos/bases/base_v.png' : 'elementos/bases/base_b.png'" class="rounded" alt="Image" :style="isMobile ? 'width:110%;' : 'width:90%; cursor:pointer;'"  @click="cambiarNav(1)">
               </b-col>
               <b-col cols="6" md="4" lg="4" xl="4" >
                   <img :src="nav == 2 ? 'elementos/rankin/rankin_v.png' : 'elementos/rankin/rankin_b.png'" class="rounded" alt="Image" :style="isMobile ? 'width:110%;' : 'width:90%; cursor:pointer;'"  @click="cambiarNav(2)">
               </b-col>
               <b-col cols="6" md="4" lg="4" xl="4" > 
                  <!-- <br v-if="isMobile"> -->
                  <img :src="nav == 3 ? 'elementos/aviso/aviso_v.png' : 'elementos/aviso/aviso_b.png'" class="rounded" alt="Image" :style="isMobile ? 'width:110%;' : 'width:90%; cursor:pointer;'"  @click="cambiarNav(3)">       
               </b-col> 
            </b-row>
        </b-col>
      </b-row>
     <div id="menoabajo"></div> 
     <template v-if="nav == 0"> 
      <b-row class="justify-content-center"> 
         <b-col cols="11" md="5" lg="12" xl="12"> 
            <center :style="!isMobile ? 'margin-top: -35px;' : 'margin-top: 25px;'">
                  <template v-if="isMobile"> 
                    <img src="elementos/home_movil.png" class="rounded" alt="Image" style="width:90%;">  
                  </template>
                  <template v-else>
                    <img src="elementos/home.png" class="rounded "  alt="Image" style="width:70%; "> 
                  </template>
            </center>
         </b-col>
      </b-row>
     </template>
      <template v-if="nav == 1">
        <center> 
          <br v-if="isMobile">
          <b-row class="justify-content-center"> 
             <b-col cols="11" md="7" lg="11" xl="11"> 
                <template  v-if="user.canal == 3">
                  <template v-if="isMobile"> 
                    <center v-if="user.perfil == 'Director' || user.perfil == 'Subdirector'"> 
                      <b-row class="justify-content-center mt-2"> 
                          <b-col cols="11" md="3">
                              <v-btn :color="base_sub == 'Ejecutivo' ? '#1975B8' : '#29486F'" class="mt-2"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="base_sub = 'Ejecutivo'"> 
                                EJECUTIVO
                              </v-btn>
                          </b-col> 
                          <b-col cols="11" md="3">
                              <v-btn :color="base_sub == 'Gerente' ? '#1975B8' : '#29486F'" class="mt-2"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="base_sub = 'Gerente'"> 
                                GERENTE
                              </v-btn>
                          </b-col> 
                          <b-col cols="11" md="3">
                              <v-btn :color="base_sub == 'Coordinador' ? '#1975B8' : '#29486F'" class="mt-2"  size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="base_sub = 'Coordinador'"> 
                                COORDINADOR
                              </v-btn>
                          </b-col>
                      </b-row>
                      <br>
                      <img v-if="base_sub == 'Ejecutivo'" src="bases/movile/base_ejecutivo.png" class="rounded mt-2" alt="Image" style="width:100%;"> 
                      <img v-if="base_sub == 'Gerente'" src="bases/movile/base_gerente.png" class="rounded mt-2" alt="Image" style="width:100%;"> 
                      <img v-if="base_sub == 'Coordinador'" src="bases/movile/base_coord.png" class="rounded mt-2" alt="Image" style="width:100%;">  
                  </center> 
                  <template v-else>
                    <img v-if="user.perfil == 'Ejecutivo'" src="bases/movile/base_ejecutivo.png" class="rounded mt-2" alt="Image" style="width:100%;"> 
                    <img v-if="user.perfil == 'Gerente'" src="bases/movile/base_gerente.png" class="rounded mt-2" alt="Image" style="width:100%;"> 
                    <img v-if="user.perfil == 'Coordinador'" src="bases/movile/base_coord.png" class="rounded mt-2" alt="Image" style="width:100%;">  
                  </template>
                  </template>
                  <template v-else>
                    <center v-if="user.perfil == 'Director' || user.perfil == 'Subdirector'"> 
                        <b-row class="justify-content-center">
                            <b-col cols="10" md="5" style="background: #0c273a; border-radius: 10px;" >  
                                <b-row class="justify-content-center" style=" margin: 5px; margin-left: -30px;" >
                                  <b-col cols="10" md="3" class="mt-1">  
                                      <u :style="base_sub == 'Ejecutivo' ? 'color:#00bb00; font-size:20px; cursor: pointer;' : 'color:#fff; font-size:20px; cursor: pointer;'"  @click="base_sub = 'Ejecutivo'">EJECUTIVO</u>
                                  </b-col>
                                  <b-col cols="10" md="3" class="mt-1">  
                                      <u :style="base_sub == 'Gerente' ? 'color:#00bb00; font-size:20px; cursor: pointer;' : 'color:#fff; font-size:20px; cursor: pointer;'" @click="base_sub = 'Gerente'">GERENTE</u>
                                  </b-col>
                                  <b-col cols="10" md="3" class="mt-1">  
                                      <u :style="base_sub == 'Coordinador' ? 'color:#00bb00; font-size:20px; cursor: pointer;' : 'color:#fff; font-size:20px; cursor: pointer;'"  @click="base_sub = 'Coordinador'">COORDINADOR</u>
                                  </b-col>   
                                </b-row>  
                            </b-col> 
                        </b-row>
                        <br>
                        <img :src="'bases/propios/' + base_sub + 'ok.png'" class="rounded mt-5" alt="Image"  >
                    </center>
                    <template v-else> <img :src="'bases/propios/' + user.perfil + '.png'" class="rounded" alt="Image"  > </template> 
                  </template>
                </template>
                <template v-if="user.canal == 4"> 
                  <img v-if="isMobile" src="bases/movile/base_especialista.png" class="rounded" alt="Image" style="width:100%;">   
                  <img v-if="!isMobile" src="bases/especialista/Especialista.png" class="rounded" alt="Image" > 
                </template>
                <template v-if="user.canal == 1">
                  <img v-if="isMobile" src="bases/movile/base_retail.png" class="rounded mt-2" alt="Image" style="width:100%;">   
                  <img v-if="!isMobile" src="bases/retail/Retail.png" class="rounded" alt="Image"  > 
                </template>
             </b-col>
          </b-row>
        </center>
     </template>
     <template v-else-if="nav == 2">
        <Ranking></Ranking>
     </template> 
     <template v-else-if="nav == 3">
      <br>
      <b-row class="justify-content-center"> 
         <b-col cols="11" md="5" lg="12" xl="12"> 
            <center :style="!isMobile ? 'margin-top: -35px;' : 'margin-top: 25px;'">
                  <template v-if="isMobile"> 
                    <img src="img/jonrronero_movil.jpg" class="rounded" alt="Image" style="width:90%;">  
                  </template>
                  <template v-else>
                    <img src="img/jonrronero.jpg" class="rounded "  alt="Image" style="width:43%; "> 
                  </template>
            </center>
         </b-col>
      </b-row>
      <br><br>
     </template> 
     <template v-else-if="nav == 7">
       <Contacto></Contacto>
     </template>
     <template v-else-if="nav == 8">
      <center>
      <br> 
       <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'"> 
          Importante
        </h1>    
        <h4 style="color:#fff; ">Todo personal interno y externo debe seguir el flujo actual  de Capacitación. <br v-if="!isMobile">
         Todas las Capacitaciones cuentan y suman puntos <br v-if="!isMobile">
          de acuerdo con el rol y canal que participas. 
        </h4>
         <br> 
         <a href="https://conocemas.ethinksites.com/login/index.php" target="_blank" style="cursor: pointer;">
          <b-img  src="elementos/conoc.png" alt="Image"   :style="isMobile ? '  width: 50%;' : 'width: 13%; cursor:pointer;'"></b-img>
         </a>
        </center> 
     </template>
      <!-- <template v-if="nav == 0 && !isMobile">
        <br v-if="!isMobile"><br v-if="!isMobile">
           <div>
                <p class="mt-2 text-white text-center"   style="font-size:40px;"><b>FALTAN...</b></p> 
                <center>
                  <h2 class="mt-3" style="cursor:pointer; font-size:110px;   margin-top:-60px;" >
                    <p id="demo">{{reloj}}</p>
                  <p id="demo2" style="margin-top:-80px;">&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:20px;">&nbsp;&nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  
                      DÍAS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;&nbsp; &nbsp; 
                      HORAS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  
                      MINS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;  
                      SEGS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    </span></p>
                  </h2> 
                   <p class="mt-2 text-white text-center"  style="font-size:40px;"><b>PARA SENTIRTE TRENDING TOPIC</b></p> 
                </center>
            </div>
      </template> -->


    <b-modal id="actualizaMRT" title=" " hide-footer hide-header no-close-on-backdrop :hideHeaderClose="true" :cancelDisabled="true" body-bg-variant="dark">
        <div class="text-center text-white" >            
            <h4 class="mt-4" style="color:#8d3fe1;" v-if="enviado == 0">Importante</h4>            
            <h4 class="mt-4" style="color:#8d3fe1;" v-else>Aviso</h4>         
            <p class="mt-4 text-white" v-if="enviado == 0 && confirma == 0">Para continuar con el ingreso <br> comparte tu AXC/MRT/MRN</p>
            <p class="mt-4 text-white" v-else-if="enviado == 1 && confirma == 0">Tu información se ha enviado exitosamente.</p>
            <b-row class="justify-content-center">
              <b-col cols="8" v-if="enviado == 0">
                <template v-if="confirma == 0">
                  <b-form-input v-model="mrt" placeholder="AXC/MRT/MRN"   type="text"  class=" text-center mt-4" />
                  <div class="text-center">
                    <p class="text-danger mt-2" v-if="errors.includes( 'mrt' )"><b>Ingresa tu AXC/MRT/MRN.</b></p>
                    <p class="text-danger mt-2" v-if="errors.includes( 'falso' )"><b>AXC/MRT/MRN Incorrecto. <br> favor de verificar.</b></p>
                  </div>
                </template>
                <template v-else>
                  <p class="mt-4 text-white">¿Es este tu AXC/MRT/MRN?.</p>
                  <b-form-input v-model="mrt" placeholder="AXC/MRT/MRN"   type="text"  class=" text-center mt-4" />
                  <b-row class="justify-content-center">
                    <b-col cols="4">
                       <v-btn  color="#8d3fe1"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="bajarModal()">
                          SI
                       </v-btn>
                    </b-col>
                    <b-col cols="4">
                       <v-btn  color="#5cb615"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="confirma = 0">
                          NO
                       </v-btn>
                    </b-col>
                  </b-row>

                </template>
                <v-btn  color="#8d3fe1"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="enviarMRT()" v-if="confirma == 0">
                  Enviar <b-spinner v-if="loader" small></b-spinner> 
                </v-btn>
                <br>
              </b-col>
              <b-col cols="8" v-else>
                <v-btn  color="#8d3fe1"  class="mt-3"  dark style="text-transform:capitalize; width: 50%;" @click="bajarModal()">
                  OK
                </v-btn>
                <br>
              </b-col>
            </b-row>
            <br>
        </div>
    </b-modal>

      <template v-if="zoom == 1">
       <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br> <br><br><br> 
      </template>
  </div>
</div>
</template>

<script>

import $ from "jquery";
import Cookies from "js-cookie"; 
import * as moment from 'moment'; 
import Contacto from './Contacto.vue'; 
import Ranking from '../Ranking/Ranking.vue'; 
export default {
  name: 'Home',
  components: { 
    Ranking,
    Contacto
  },
  data(){
    return{
      zoom:0,
      isMobile: this.$mobile(),
      loader:false,
      nav:0,
      countdown: moment.utc(61).format('DD:HH:mm:ss'),
      expires_in: null,
      interval: null,
      Clickinterval: false,
      reloj: "",
      mrt:"",
      errors:[],
      enviado:0,
      actualiza:0,
      confirma:0,
      drawer: false,
      group: null,
      items: [
        {
          title: 'Foo',
          value: 'foo',
        },
        {
          title: 'Bar',
          value: 'bar',
        },
        {
          title: 'Fizz',
          value: 'fizz',
        },
        {
          title: 'Buzz',
          value: 'buzz',
        },
      ],
      vertical:0,
      base_sub:"Ejecutivo"
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  created(){
     this.$bus.$on("actualiza_vertical",
      () => {
        this.vertical = 1;
      });

  },
  methods:{
    apibitacora(id){
      var seccion = "";
      switch (id) {
        case 1:
          seccion = 'Bases';
        break; 
        case 2:
          seccion = 'Rankin';
        break;
        case 3:
          seccion = 'Avisos importantes';
        break;
        case 4:
          seccion = 'Likes';
        break;
        case 5:
          seccion = 'Hashtags Ocultos';
        break;
        case 6:
          seccion = 'Los + Virales';
        break;
        case 7:
          seccion = 'Contacto';
        break;
        case 8:
          seccion = 'Capacitación';
        break;
      }
     this.$api.post('auth/bitacora', {
          'correo' : this.user.email,
          'mrt' : this.user.mrt,
          'canal' : this.user.canal,
          'perfil' : this.user.perfil,
          'region' : 'N/A',
          'url' : id == 9 ?  '/' : '/' + seccion,
          'seccion' : id == 9 ? 'Home' : seccion,
          'accion' : id == 9 ? 'Cerro Sesión'  : 'Accedio',
          'detalle' : id == 9 ? 'Salio de convención' : 'Consulto la sección ' + seccion,
        }).then(
          response => {
             console.log(response);
          })
    },
    cambiarNav(id){
      console.log(id);
      if (this.isMobile)
          {
             this.$bus.$emit("cambi_fo2", true)
             window.scroll(0, 200);
          } 

        this.nav = id; 

        this.apibitacora(this.nav);


        if(!this.isMobile && this.nav == 2){
           this.$bus.$emit("fondo_capa", "fondoSolo")
        }


        if (this.isMobile && this.nav == 1) {
           this.$bus.$emit("cambi_fo6")
        }



        // else if (!this.isMobile && this.nav != 0)
        // {
        //    this.$bus.$emit("fondosolo", true)
        // }else if(!this.isMobile && this.nav == 0){
        //    this.$bus.$emit("fondosolo2", true)
        // }

        // if (this.isMobile && this.nav == 0)
        // {
        //  location.reload(); 
        // }
    },
    aceptaBienvenida(){
      this.loader = true;
      var url= 'auth/bienvenida?id='+ this.user.id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.loader = false;
                this.$router.push("/");       
            }
        );
    },
    cerrarSesion(){
       this.apibitacora(9);

      setTimeout(() => {
          this.$store.commit(
              "setLoginStatus",
              false
          );
          this.$store.commit(
              "setInfoUser",
              null
          );    
          Cookies.remove("session_id");
          location.reload();
        }, 700)
    },
    setInterval: function() {
        this.interval = setInterval(() => {
            var countDownDate = new Date("Feb 09, 2024 10:00:00").getTime();

                    // Get today's date and time
              var now = new Date().getTime();
                
              // Find the distance between now and the count down date
              var distance = countDownDate - now;

            
                
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var ceroD = days < 10 ? '0' + days : days;
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var ceroH = hours < 10 ? '0' + hours : hours;
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var ceroM = minutes < 10 ? '0' + minutes : minutes;
            var seconds =  Math.floor((distance % (1000 * 60)) / 1000);
            var ceroS = seconds < 10 ? '0' + seconds : seconds ;
            // Output the result in an element with id="demo"
            this.reloj =  ceroD  + " . " +   ceroH + " . " +  ceroM + " . " +   ceroS;         

         }, 1000);
    },
    enviarMRT(){
      this.errors = [];
      if (!this.mrt) {
        this.errors.push('mrt');   
      }
      if(this.mrt.includes("AXC") == false && this.mrt.includes("MRT") == false && this.mrt.includes("MRN") == false){
        this.errors.push('falso');   
      }else{
        this.loader = true;
        var url= 'home/actualiza?id='+ this.user.id + '&mrt='+ this.mrt;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.loader = false;
                    this.enviado = 1;                   
                }
            );
      }
    },
    bajarModal(){
      this.enviado = 0;
      this.$bvModal.hide('actualizaMRT');
    },
    traeAct(){
      var url= 'home/trae?id='+ this.user.id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                if (this.user.perfil == 'Ejecutivo')
                  {
                    if (!data.actualiza)
                    {
                      this.confirma = 0;
                    }else{
                      this.confirma = 1;
                      this.mrt = data.actualiza;
                    }

                    this.$bvModal.show('actualizaMRT');
                  }     
            }
        );
    }
  },
  mounted(){
    this.setInterval();
     var height  = $(window).height();
     console.log(height);
     if (height < 950 && !this.isMobile)
     {
       document.body.style.zoom='75%'; 
       this.zoom = 1;
     } 

     if (window.orientation == '90' && this.isMobile || window.orientation == '-90'  && this.isMobile )
     {
       this.vertical = 1;
     } 

    if (this.isMobile && this.nav == 0) {
        this.$bus.$emit("cambi_fo7")
    }

     this.$bus.$emit("fondo_capa", "fondoSolo")

  }
}

</script>
<style >


#demo{
   -webkit-text-stroke: 2px #fff;
  color: #fff; 
}
#demo2{
   -webkit-text-stroke: 0.1px #fff;
  color: #fff; 
}

@media only screen and (min-width: 1000px) 
{
        #menus
        {
            height: 30%;
            font-size: 12px;
            width: 100%;
            margin: 90px;
             margin-left: -10px;
        }

                     #faltan
        {
          margin-left: 230px;
        }
        #fotoN{
          margin-top: 32px;
        }

        #fotoS{
           width: 90%;
           margin-top: -10px;
        } 
}
@media only screen and (min-width: 1300px) 
{
        #menus
        {
            height: 30%;
            font-size: 12px;
            width: 100%;
            margin: 80px;
              margin-left: -10px;
        }

             #faltan
        {
          margin-left: 260px;
        }

        #fotoN{
          margin-top: 25px;
        } 

        #fotoS{
           width: 70%;
           margin-top: -10px;
        } 

}
@media only screen and (min-width: 1400px)
{
        #menus
        {
            height: 30%;
            font-size: 15px;
            width: 100%;
            margin: 80px;
              margin-left: -10px;
        }

        #faltan
        {
          margin-left: 340px;
        }

        #fotoN{
          margin-top: 18px;
        }

        #fotoS{
           width: 70%;
           margin-top: -10px;
        }
}
@media only screen and (min-width: 1600px)
{
        #menus
        {
            height: 30%;
            font-size: 15px;
            width: 60%;
            margin: 80px;
              margin-left: -10px;
        }

         #faltan
        {
          margin-left: 425px;
        }

        #fotoN{
          margin-top: -10px;
        }

        #fotoS{
           width: 60%;
           margin-top: -10px;
        }
}
@media only screen and  (min-width: 1800px)
{
        #menus
        {
            height: 35%;
            font-size: 15px;
            width: 100%;
            /* margin: 80px; */
            margin-left: -10px;
        }

        #faltan
        {
          margin-left: 600px;
        }

        #fotoN{
          margin-top: -10px;
        }

        #fotoS{ 
          width: 50%;
          margin-top: -10px;
        }
} 
</style>